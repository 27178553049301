import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";

function Login() {
 
  
    const [emailInput, setEmailInput] = useState('');
    const [passwordInput, setPasswordInput] = useState('');
    
    const navigate = useNavigate();
    
    const handleEmailChange = (e) => {
        setEmailInput(e.target.value);
    }
    
    const handlePasswordChange = (e) => {
        setPasswordInput(e.target.value);
    }
    
    const handleLoginSubmit = (e) => {
        e.preventDefault();
        let hardcodedCred = {
            email: 'leoni',
            password: 'leoni'
        }
    
        if ((emailInput == hardcodedCred.email) && (passwordInput == hardcodedCred.password)) {

            const token = '123456abcdef';
            sessionStorage.setItem('auth-token', token);
            navigate('home');
        } else {
            alert('email et/ou mot de passe incorrect(s)');
        }
    }
    
    return (
        <div className="login-page">
            <h2>Log In</h2>
            <form autoComplete="off" onSubmit={handleLoginSubmit}>
                <div className="form-group">
                    <input
                    className="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    placeholder="Enter email"
                    value={emailInput}
                    onChange={handleEmailChange}
                    />
                </div>
                <div className="form-group">
                    <input
                    type="password"
                    autoComplete="new-password"
                    className="form-control"
                    id="exampleInputPassword1"
                    placeholder="Password"
                    value={passwordInput}
                    onChange={handlePasswordChange}
                    />
                </div>
                <button type="submit">
                Log In
                </button>
          </form>
        </div>
      );
}

export default Login;