import React from 'react';
import { Route, Navigate } from 'react-router-dom';

function ProtectedRoute({ children }) {
  // Check if the user is authenticated (you can use your authentication logic here)
  const isAuthenticated = !!sessionStorage.getItem('auth-token');

  return isAuthenticated ? children : <Navigate to="/" replace={true} state={{ from: '/home' }} />;
}

export default ProtectedRoute;
