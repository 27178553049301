import logo from './graiet.png';
import './App.css';
import InterestCalculatorPage from './InterestCalculatorPage';
import Login from './login';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ProtectedRoute from './ProtectedRoute';

function App() {
  return (
    <div className="App">
         <div className="container">
      <header> 
        <div className="logo-container">
            <img src={logo} alt="Logo" className="logo" />
                    
              </div>
              </header>   
       
              <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />}/>

        <Route
          path="/home"
          element={
            <ProtectedRoute>
              <InterestCalculatorPage />
            </ProtectedRoute>
          }
        />
      </Routes>
    </BrowserRouter>
    </div></div>
  );
}

export default App;
