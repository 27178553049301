import React, { useState } from 'react';
import logo from './graiet.png';
function InterestCalculatorPage() {
  const [principal, setPrincipal] = useState(0);
  const [results, setResults] = useState([]);
  const [calculating, setCalculating] = useState(false);
  const calculateInterest = async (e) => {
    e.preventDefault(); // Empêche le rechargement de la page lorsque le formulaire est soumis
    setCalculating(true); // Indique que le calcul est en cours
    const months = 18;
    const results = [];
    let principalWithoutCard = principal;
    let principalWithCard = 0;
    let WithCard = 0;
    let withoutCard = 0;
    let ecartPrime=0 ;
    let ecatP=0 ;
    let ecart = 0;
   let tauxx=2.25;
    for (let month = 1; month <= months; month++) {
      let totP=0;
      let tot=0;
      if (month < 7) {
        // Apply specific percentages for the first 6 months
        let taux=principal * (month/100);
        console.log(month+','+taux );
        tot=principal+taux ;
        principalWithoutCard = principal+taux;
       //  console.log(principal+taux);
        withoutCard = principalWithoutCard / month;
        // Utilisation de la variable withoutCard
       // console.log('ttttt :'+p);
        WithCard=( (((month/ 100)*( 1-0.20))*principal)+principal)/month ;
        //console.log('card : '+WithCard)  ;
        totP=( (((month/ 100)*( 1-0.20))*principal)+principal);
      }
      if (month >6 && month <=12) {
        // Apply specific percentages for the first 6 months
        let taux12 = (month*1.25) ;
         console.log(month+','+taux12 );
         tot=principal+(principal*(taux12/100)) ;
         principalWithoutCard = principal+(principal*(taux12/100));
          console.log(principal+taux12);
          withoutCard = principalWithoutCard / month;
          // Utilisation de la variable withoutCard
          let p = taux12-0.2;
         // console.log('ttttt :'+p);
          WithCard=( ((taux12/ 100)*(1 -  0.20))*principal+principal)/month ;
          totP=( ((taux12/ 100)*(1 -  0.20))*principal+principal);
      }
      if (month >12 && month <=15) {
        let taux12=month*1.5 ;
        console.log(month+','+taux12 );
        tot=principal+(principal*(taux12/100));
        principalWithoutCard = principal+(principal*(taux12/100));
        withoutCard = principalWithoutCard / month;
        WithCard=(((taux12/ 100)*(1 -  0.20))*principal+principal)/month ;
        totP=(((taux12/ 100)*(1 -  0.20))*principal+principal);
      }
      if (month >15 ) {
        let taux15=month*1.75 ;
        console.log(month+','+taux15 );
        principalWithoutCard = principal+(principal*(taux15/100));
        tot=principal+(principal*(taux15/100))
        //  console.log(principal+taux);
         withoutCard = principalWithoutCard / month;
         // Utilisation de la variable withoutCard
         let p = principalWithoutCard*0.2;
        // console.log('ttttt :'+p);
         WithCard=( ((taux15/ 100)*(1 - 0.20))*principal+principal)/month ;
         totP=(((taux15/ 100)*(1 -  0.20))*principal+principal);
      }
      //let interestWithCard = principalWithCard / month;
     // console.log(tauxx/100);
      let interestParticularClient = (principal+(principal*(tauxx/100)))/month;
      let interestParticularClientWithCard =( ((tauxx/ 100)*(1 -  0.20))*principal+principal)/month
      ecartPrime=(interestParticularClientWithCard*month)-totP;
      ecart=((principal+(principal*(tauxx/100))))-tot ;
        console.log('month : '+month+' : '+(( ((tauxx/ 100)*(1 -  0.20))*principal+principal)));
      results.push({
        month,
        withCard: String(WithCard.toFixed(2)).replace('h', '.').replace(',', '.'),
        withoutCard: String(withoutCard.toFixed(2)).replace('h', '.').replace(',', '.'), // Utilisation de la variable withoutCard
        particularClient: String(interestParticularClient.toFixed(2)).replace('h', '.').replace(',', '.'),
        particularClientWithCard: String(interestParticularClientWithCard.toFixed(2)).replace('h', '.').replace(',', '.'),
        ecartP:String(ecartPrime.toFixed(2)).replace('h', '.').replace(',', '.'),
        ecart:String(ecart.toFixed(2)).replace('h', '.').replace(',', '.'),
      });
      tauxx += 2.25;
    }
    // Simulate a delay (replace this with your actual calculation logic)
    await new Promise((resolve) => setTimeout(resolve, 1000));
    setResults(results);
    setCalculating(false); // Indique que le calcul est terminé
  };
  return (
    <>
    <section className="ftco-section">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-6 text-center mb-5">
              <h2 className="heading-section">Calculateur de Taux d'Intérêt</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <form onSubmit={calculateInterest} style={{ display: 'flex', alignItems: 'center', marginBottom: '1.5rem' }}>
        <div style={{ marginRight: '10px', flex: 1 }}>
          <label style={{ width: '20%' }}>Montant Principal :</label>
          <input
            type="number"
            step="0.01"
            value={principal}
            onChange={(e) => setPrincipal(parseFloat(e.target.value))} />
        </div>
        <button type="submit" style={{ flex: 'none' }}>Calculer</button>
      </form>
      {calculating &&
      <div className="spin-wrapper">
  <div className="spinner">
  </div>
</div>}
{results.length > 0 && !calculating && (  <div className="table-wrap">
                <table className="table">
                  <thead className="thead-primary">
                    <tr>
                    <th>Nombre de Mois</th>
              <th>Montant d'échéance Sans Carte prime</th>
              <th>Montant d'échéance Avec Carte prime</th>
              <th>Montant d'échéance Sans Carte prime 'Client Particulier '</th>
              <th>Montant d'échéance Avec Carte prime 'Client Particulier '</th>
              <th>Ecart Leoni/Particulier</th>
              <th>Ecart Leoni Prime / Particulier Prime </th>
                    </tr>
                  </thead>
                  <tbody>
            {results.map((result, index) => (
              <tr key={index}>
                <td>{result.month}</td>
                <td>{result.withoutCard}</td>
                <td>{result.withCard}</td>
                <td>{result.particularClient}</td>
                <td>{result.particularClientWithCard}</td>
                <td>{result.ecart}</td>
                <td>{result.ecartP}</td>
              </tr>
            ))}
          </tbody>
                </table>
              </div>
)}
            </div>
          </div>
        </div>
      </section></>
);
}
export default InterestCalculatorPage;